/* eslint-disable no-console */
import { registerApplication, start } from "single-spa";

type Organization = { organizationId: string };
type Token = {};
type User = { userId: string };

// Shared state managed by the root config
let rootState: {
  organization: Organization | null;
  token: Token | null;
  user: User | null;
} = {
  organization: null,
  token: null,
  user: null,
};

// Callback functions to update the shared state
const setOrganization = (org: Organization): void => {
  // console.log("root.setOrganization=", org);
  rootState.organization = { ...org };
  // Dispatch a custom event that applications can listen for
  window.dispatchEvent(
    new CustomEvent("rootStateChanged", {
      detail: { type: "SET_ORGANIZATION", org },
    })
  );
};

const setToken = (token: Token): void => {
  // console.log("root.setOrganization=", org);
  rootState.token = { ...token };
  // Dispatch a custom event that applications can listen for
  window.dispatchEvent(
    new CustomEvent("rootStateChanged", {
      detail: { type: "SET_TOKEN", token },
    })
  );
};

const setUser = (user: User): void => {
  // console.log("root.setUser=", user);
  rootState.user = { ...user };
  // Dispatch a custom event that applications can listen for
  window.dispatchEvent(
    new CustomEvent("rootStateChanged", {
      detail: { type: "SET_USER", user },
    })
  );
};

// Props to pass to both apps
const customProps = {
  organization: rootState.organization,
  token: rootState.token,
  user: rootState.user,
  setOrganization,
  setToken,
  setUser,
};

registerApplication({
  name: "console-main-v2",
  app: () => loadApp("@fgl/console-main-v2") as Promise<any>,
  activeWhen: ["/", "/activities", "/import", "/report/processor"],
  customProps,
});

registerApplication({
  name: "console-main-v1",
  app: () => loadWithoutAmd("@fgl/console-main-v1") as Promise<any>,
  activeWhen: [
    "/person/history",
    "/retail/home",
    "/retail/accounts",
    "/retail/campers",
    "/retail/employees",
    "/retail/reports",
    "/retail/care-packages",
    "/retail/settings",
    "/retail/transactions",
  ],
  customProps,
});

registerApplication({
  name: "console-config-v2",
  app: () => loadApp("@fgl/console-config-v2") as Promise<any>,
  activeWhen: ["/config"],
  customProps,
});

registerApplication({
  name: "console-inventory-v2",
  app: () => loadApp("@fgl/console-inventory-v2") as Promise<any>,
  activeWhen: [
    "/retail/inventory",
    "/retail/inventory/catalog",
    "/retail/inventory/item",
    "/retail/inventory/history",
    "/retail/inventory/layout",
    "/retail/inventory/lot",
    "/retail/inventory/pdetail",
    "/retail/inventory/pdetails",
    "/retail/inventory/purchasedetail",
    "/retail/inventory/purchasedetails",
    "/retail/inventory/porder",
    "/retail/inventory/porders",
    "/retail/inventory/purchaseorder",
    "/retail/inventory/purchaseorders",
    "/retail/inventory/screen",
    "/retail/inventory/vendor",
    "/retail/inventory/vendors",
    "/retail/vendor",
    "/retail/vendors",
  ],
  customProps,
});

// registerApplication({
//   name: "console-attendance-v2",
//   app: () => loadApp("@fgl/console-attendance-v2") as Promise<any>,
//   activeWhen: ["/attendance"],
// });

// registerApplication({
//   name: "console-health-v2",
//   app: () => loadApp("@fgl/console-health-v2") as Promise<any>,
//   activeWhen: ["/health"],
// });

// registerApplication({
//   name: "console-nav-v2",
//   app: () => System.import("@fgl/console-nav-v2") as Promise<any>,
//   activeWhen: ["/nav"],
// });

// registerApplication({
//   name: "console-retail-v2",
//   app: () => loadApp("@fgl/console-retail-v2") as Promise<any>,
//   activeWhen: ["/retail/stats", "/retail/upload"],
// });

registerApplication({
  name: "portal-main-v2",
  app: () => System.import("@fgl/portal-main-v2") as Promise<any>,
  activeWhen: ["/portal"],
  customProps,
});

start();

function loadApp(name: string) {
  console.log("root.loadApp |", name);
  return Promise.resolve()
    .then(() => {
      loadAppShowSpinner();
      return System.import(name);
    })
    .then((app) => {
      loadAppHideSpinner();
      return app;
    });
}

function loadAppShowSpinner() {
  // const imgElement = document.createElement('img');
  // Object.assign(imgElement, {
  //   id: 'single-spa-loader-image',
  //   src: 'loading.gif'
  // });
  // document.body.appendChild(imgElement);

  const loaderElement = document.createElement("div");
  loaderElement.id = "single-spa-loader-text";
  const textNode = document.createTextNode("Loading...");
  loaderElement.appendChild(textNode);
  document.body.appendChild(loaderElement);
}

function loadAppHideSpinner() {
  const imgEl = document.getElementById("single-spa-loader-image");
  if (imgEl) imgEl.remove();
  const textEl = document.getElementById("single-spa-loader-text");
  if (textEl) textEl.remove();
}

// A lot of angularjs libs are compiled to UMD, and if you don't process them with webpack
// the UMD calls to window.define() can be problematic.
function loadWithoutAmd(name: string) {
  return Promise.resolve().then(() => {
    loadAppShowSpinner();
    let globalDefine = (window as any).define;
    delete (window as any).define;
    return System.import(name)
      .then((module) => {
        loadAppHideSpinner();
        (window as any).define = globalDefine;
        // eslint-disable-next-line no-console
        console.log("loadWithoutAmd", name, "then", module);
        if (!module.unmount) {
          // eslint-disable-next-line no-console
          // console.log("window.location.pathname", window.location.pathname);
          let path = "";
          if (window.location.pathname.startsWith("/retail")) path = "/retail";
          window.location.href =
            window.location.protocol + "//" + window.location.hostname + path;
        }
        return module;
      })
      .catch((e) => {
        loadAppHideSpinner();
        // eslint-disable-next-line no-console
        console.log("loadWithoutAmd", name, "catch", e);
        return Promise.reject(e);
      });
  });
}
